import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify';
import { encryptData, decryptData } from './utils';

const tokenAxiosInstance = axios.create();
tokenAxiosInstance.interceptors.request.use(
  config => {
    const userRole = localStorage.getItem('roles') ? decryptData('roles') : '';
    const guestEnabledEndpoints = ['bulkprovision/imei', 'bulkprovision/tbox', 'device/blacklist', 'command/vinno', 'bulkprovision/demo', 'users', 'idm/portal'];
    const token = decryptData('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.headers['Access-Control-Allow-Origin'] = '*';

      if (!userRole.includes('RM_admin') && !userRole.includes('RM_user')) {
        const requestedEndpoint = config.url.split('/').slice(3, 5).toString().replace(',', '/').split(",")[0];
        if (guestEnabledEndpoints.includes(requestedEndpoint)) {
          toast.error('Unauthorized Access');
          return Promise.reject(new AxiosError('Unauthorized Access', '403'));
        }
      }
    }
    return config;
  },
  error => {
    return Promise.reject(new Error(error.message || 'Request interceptor error'));
  }
);

tokenAxiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401) {
      try {
        await getRefreshToken(originalRequest);
        return tokenAxiosInstance(originalRequest);
      } catch (refreshError) {
        // If token refresh fails, redirect to login
        toast.error('Session expired. Please login again.');
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject(refreshError instanceof Error ? refreshError : new Error(refreshError?.message || 'Token refresh failed'));
      }
    }

    if (error?.response?.status === 403) {
      toast.error('Unauthorized Access!');
    }

    return Promise.reject(error instanceof Error ? error : new Error(error?.message || 'Request failed'));
  }
);

const getRefreshToken = async (config) => {
  const token = localStorage.getItem('token') ? decryptData('token') : '';
  const token2 = localStorage.getItem('token2') ? decryptData('token2') : '';
  const refresh_token = localStorage.getItem('refresh_token1') ? decryptData('refresh_token1') : '';
  const refresh_token2 = localStorage.getItem('refresh_token2') ? decryptData('refresh_token2') : '';

  const currentToken = config.headers.Authorization.replace('Bearer', '').trim();

  let refreshData;
  if (currentToken === token) {
    refreshData = {
      token: refresh_token,
      clientId: process.env.REACT_APP_CLIENT_ID_USER,
      clientSecret: process.env.REACT_APP_CLIENT_SECRET_USER,
      accountId: process.env.REACT_APP_ACCOUNT_ID,
    };
  } else if (currentToken === token2) {
    refreshData = {
      token: refresh_token2,
      clientId: process.env.REACT_APP_CLIENT_ID_THING,
      clientSecret: process.env.REACT_APP_CLIENT_SECRET_THING,
      accountId: process.env.REACT_APP_ACCOUNT_ID,
    };
  } else {
    throw new Error('Invalid token');
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_STAGING_URL}/authentication/refreshtoken`, refreshData);
    const newToken = response.data.access_token;
    
    if (currentToken === token) {
      encryptData('token', newToken);
    } else {
      encryptData('token2', newToken);
    }

    config.headers.Authorization = `Bearer ${newToken}`;
    toast.success('Token restored successfully');
  } catch (error) {
    toast.error('Failed to restore the token. Please login again.');
    throw error;
  }
};

export default tokenAxiosInstance;
