import CryptoJS from 'crypto-js';
import tokenAxiosInstance from './tokenInterceptor.js';

export const logActivity = async (log) => {
    const user = decryptData('userName');
    const role = decryptData('roles').toString();
    const body = {
        user: user,
        role: role,
        activityType: log.activityType,
        activityDescription: log.activityDescription,
        tcuSerialNumber: log.tcuSerialNumber,
        status: log.status
    }
    await tokenAxiosInstance.post(`${process.env.REACT_APP_STAGING_URL}/logs/activityLogs`, body)
        .then(
            console.log("log added succussfully")
        )
        .catch((err) => {
            console.log(err);
        })
}

export const encryptData = (name, data) => {
    if (!process.env.REACT_APP_SECRET_KEY) {
        return;
    }

    try {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
        localStorage.setItem(name, encrypted);
    } catch (error) {
        return;
    }
};

export const decryptData = (name) => {
    if (!process.env.REACT_APP_SECRET_KEY) {
        return null;
    }

    try {
        const encrypted = localStorage.getItem(name);
        if (!encrypted) {
            return null;
        }

        const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch (error) {
        return null;
    }
};