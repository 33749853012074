import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/
const Login = lazy(() => import('../views/login/Login.jsx'));
const Dashboard = lazy(() => import("../views/dashboard/Dashboard.jsx"));
const ThingManagement = lazy(() => import("../views/thing/ThingManagement.jsx"));
const ThingDetails = lazy(() => import("../views/thing/ThingDetails.jsx"));
const GenericThingDetails = lazy(() => import("../views/thing/GenericThingDetails.jsx"));
const ScanConf = lazy(() => import("../views/thing/ScanConf.jsx"))
const ManualConf = lazy(() => import("../views/thing/ManualConf.jsx"))
const BulkConf = lazy(() => import("../views/thing/BulkConf.jsx"))
const GenericConf = lazy(() => import("../views/thing/GenericConf.jsx"))
const Connectivity = lazy(() => import("../views/connectivity/Connectivity.jsx"));
const Infrastructure = lazy(() => import("../views/infrastructure/Infrastructure.jsx"));
const Platform = lazy(() => import("../views/platform/Platform.jsx"));
const Cost = lazy(() => import("../views/cost/Cost.jsx"));
const Demo = lazy(() => import("../views/demo/Demo.jsx"));
const SearchVehicle = lazy(() => import("../views/emulator/SearchVehicle.jsx"));
const VehicleDetails = lazy(() => import("../views/emulator/VehicleDetails.jsx"));
const EmulatorPlayground = lazy(() => import("../views/emulator/EmulatorPlayground.jsx"));
const PingTest = lazy(() => import("../views/emulator/Emulator.jsx"));
const Campaign = lazy(() => import("../views/campaign/Campaign.jsx"));
const CreateCampaign = lazy(() => import("../views/campaign/CreateCampaign.jsx"));
const InProgressCampaignDetails = lazy(() => import(("../views/campaign/InProgressCampaignDetails.jsx")));
const ActiveCampaignDetails = lazy(() => import(("../views/campaign/ActiveCampaignDetails.jsx")));
const DraftCampaignDetails = lazy(() => import(("../views/campaign/DraftCampaignDetails.jsx")));
const CompletedCampaignDetails = lazy(() => import(("../views/campaign/CompletedCampaignDetails.jsx")));
const PausedCampaignDetails = lazy(() => import(("../views/campaign/PausedCampaignDetails.jsx")));
const CancelledCampaignDetails = lazy(() => import(("../views/campaign/CancelledCampaignDetails.jsx")));
const EditDraftCampaign = lazy(() => import(("../views/campaign/EditDraftCampaign.jsx")));
const EditCancelledCampaign = lazy(() => import(("../views/campaign/EditCancelledCampaign.jsx")));
const EditCompletedCampaign = lazy(() => import(("../views/campaign/EditCompletedCampaign.jsx")));
const Batch = lazy(() => import(("../views/campaign/Batch.jsx")));
const CreateBatch = lazy(() => import(("../views/campaign/CreateBatch.jsx")));
const BatchDetails = lazy(() => import(("../views/campaign/BatchDetails.jsx")));
const EditBatch = lazy(() => import(("../views/campaign/EditBatch.jsx")))
const Administration = lazy(() => import("../views/administration/Administration.jsx"));
const UserDetails = lazy(() => import('../views/administration/UserDetails.jsx'));
const AddUser = lazy(() => import('../views/administration/AddUser.jsx'));
const PlatformUser = lazy(() => import('../views/administration/PlatformUserDetails'));
const Home = lazy(() => import('../views/home/Home.jsx'));
const Devices = lazy(() => import('../views/thing/ThingListing.jsx'));
const AddThing = lazy(() => import('../views/thing/AddThing.jsx'));
const Topics = lazy(() => import('../views/thing/Topic.jsx'));
const Performance = lazy(() => import('../views/performance/Performance.jsx'));
const ActivityLog = lazy(() => import('../views/activityLog/ActivityLog.jsx'));
const ReportsPage = lazy(() => import('../views/reportsPage/ReportsPage.jsx'));
const Policy = lazy(() => import('../views/thing/Policy.jsx'));
const NotFound = lazy(() => import('../components/404/NotFound.jsx'));

const ROLES = {
  RM_ADMIN: 'RM_admin',
  RM_USER: 'RM_user',
  UAP_ADMIN: 'UAP_admin',
  UAP_USER: 'UAP_user',
  GUEST: 'guest',
  FOTA_ADMIN: 'FOTA_admin'
};

const hasRequiredRole = (userRoles, allowedRoles) => userRoles.some(role => allowedRoles.includes(role));

const protectRoute = (element, allowedRoles, userRoles) => {
  return hasRequiredRole(userRoles, allowedRoles) ? element : <NotFound />;
};

const ThemeRoutes = (isLogged, roles) => {
  const basicUsers = [ROLES.RM_ADMIN, ROLES.RM_USER, ROLES.UAP_ADMIN, ROLES.UAP_USER, ROLES.GUEST];
  const uapUsers = [ROLES.UAP_ADMIN, ROLES.UAP_USER];
  const performanceUsers = [ROLES.UAP_ADMIN, ROLES.UAP_USER, ROLES.GUEST, ROLES.RM_ADMIN];

  return [
    {
      path: '/',
      element: !isLogged ? <Login /> : <Navigate to="/app/dashboard" />
    },
    {
      path: '*',
      element: <NotFound />
    },
    {
      path: '/home',
      element: <Home />
    },
    {
      path: "/app",
      element: isLogged ? <FullLayout /> : <Navigate to="/" />,
      children: [
        { path: "", element: protectRoute(<Navigate to="dashboard" />, basicUsers, roles) },
        { path: "dashboard", element: protectRoute(<Dashboard />, basicUsers, roles) },
        { path: "thingManagement", element: protectRoute(<AddThing />, basicUsers, roles) },
        { path: "thingManagement/scan-configuration", element: protectRoute(<ScanConf />, basicUsers, roles) },
        { path: "thingManagement/manual-configuration", element: protectRoute(<ManualConf />, basicUsers, roles) },
        { path: "thingManagement/bulk-configuration", element: protectRoute(<BulkConf />, basicUsers, roles) },
        { path: "thingManagement/generic-configuration", element: protectRoute(<GenericConf />, basicUsers, roles) },
        { path: "devices", element: protectRoute(<ThingManagement />, basicUsers, roles) },
        { path: "devices/thingDetails", element: protectRoute(<ThingDetails />, basicUsers, roles) },
        { path: "genericThingList", element: protectRoute(<Devices />, basicUsers, roles) },
        { path: "genericThingList/genericThingDetails", element: protectRoute(<GenericThingDetails />, basicUsers, roles) },
        { path: "genericThingList/genericThingDetails/topics", element: protectRoute(<Topics />, basicUsers, roles) },
        { path: "genericThingList/genericThingDetails/policy", element: protectRoute(<Policy />, basicUsers, roles) },
        { path: "pingTest", element: protectRoute(<PingTest />, basicUsers, roles) },
        { path: "demo", element: protectRoute(<Demo />, basicUsers, roles) },
        { path: "campaign", element: protectRoute(<Campaign />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/create-campaign", element: protectRoute(<CreateCampaign />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/pogress-campaign-details", element: protectRoute(<InProgressCampaignDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/active-campaign-details", element: protectRoute(<ActiveCampaignDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/draft-campaign-details", element: protectRoute(<DraftCampaignDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/draft-campaign-details/edit-draft-campaign", element: protectRoute(<EditDraftCampaign />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/completed-campaign-details", element: protectRoute(<CompletedCampaignDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/completed-campaign-details/edit-completed-campaign", element: protectRoute(<EditCompletedCampaign />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/paused-campaign-details", element: protectRoute(<PausedCampaignDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/cancelled-campaign-details", element: protectRoute(<CancelledCampaignDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "campaign/cancelled-campaign-details/edit-cancelled-campaign", element: protectRoute(<EditCancelledCampaign />, [ROLES.FOTA_ADMIN], roles) },
        { path: "batches", element: protectRoute(<Batch />, [ROLES.FOTA_ADMIN], roles) },
        { path: "batches/create-batch", element: protectRoute(<CreateBatch />, [ROLES.FOTA_ADMIN], roles) },
        { path: "batches/batch-details", element: protectRoute(<BatchDetails />, [ROLES.FOTA_ADMIN], roles) },
        { path: "batches/batch-details/edit-batch", element: protectRoute(<EditBatch />, [ROLES.FOTA_ADMIN], roles) },
        { path: "administration", element: protectRoute(<Administration />, [ROLES.UAP_ADMIN], roles) },
        { path: "userDetails", element: protectRoute(<UserDetails />, [ROLES.UAP_ADMIN], roles) },
        { path: "administration/addUser", element: protectRoute(<AddUser />, [ROLES.UAP_ADMIN], roles) },
        { path: "administration/platformUser", element: protectRoute(<PlatformUser />, [ROLES.UAP_ADMIN], roles) },
        { path: "emulator/search-vehicle", element: protectRoute(<SearchVehicle />, uapUsers, roles) },
        { path: "emulator/vehicle-details", element: protectRoute(<VehicleDetails />, uapUsers, roles) },
        { path: "emulator/playground", element: protectRoute(<EmulatorPlayground />, uapUsers, roles) },
        { path: "platform", element: protectRoute(<Platform />, [ROLES.UAP_ADMIN], roles) },
        { path: "performance", element: protectRoute(<Performance />, performanceUsers, roles) },
        { path: "activityLog", element: protectRoute(<ActivityLog />, uapUsers, roles) },
        { path: "reports", element: protectRoute(<ReportsPage />, uapUsers, roles) },
        { path: "cost", element: <Cost /> },
        { path: "connectivity", element: <Connectivity /> },
        { path: "infrastructure", element: <Infrastructure /> },
        { path: "*", element: <NotFound /> }
      ]
    }
  ];
};

export default ThemeRoutes;