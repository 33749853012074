import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { decryptData } from './services/utils';

const App = () => {
  const roles = localStorage.getItem('roles') ? decryptData('roles') : [];
  const token = localStorage.getItem('token') ? decryptData('token') : null;
  const isLogged = token && roles?.length > 0;
  const routing = useRoutes(Themeroutes(isLogged, roles));

  return (
      <div className="dark">
        {routing}
      </div>
  );
};

export default App;
